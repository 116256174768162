<template>
  <v-container>
    <v-row>
      <v-col
          cols="12"
          md="4"
          sm="6"
          v-for="i in 10"
          :key="i"
      >
        <v-skeleton-loader
            type="image, article"
        ></v-skeleton-loader>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  data: () => ({
  }),
}
</script>